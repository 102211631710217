import ReactHtmlParser from "react-html-parser"

const htmlString = `

<div class=WordSection1>
<p class=MsoNormal align=center style='text-align:center;line-height:normal'><b><u><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p><span
 style='text-decoration:none'>&nbsp;</span></o:p></span></u></b></p>

<p class=MsoNormal align=center style='text-align:center;line-height:normal'><span
class=SpellE><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black;mso-ansi-language:EN-US'>LifestyleDAO</span></b></span><b><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
mso-ansi-language:EN-US'> (Membership Tokens) are intended as membership access
token items<o:p></o:p></span></b></p>

<p class=MsoNormal align=center style='text-align:center;line-height:normal'><b><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
mso-ansi-language:EN-US'>for individual enjoyment only, not for investment
vehicles or multi-person use.<o:p></o:p></span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><b><u><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p><span
 style='text-decoration:none'>&nbsp;</span></o:p></span></u></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'><span
style='mso-tab-count:1'>            </span>This Terms of Sale, Use and
Membership Agreement (“Agreement”) is entered into this date of purchase,
between (“Purchaser”) and <span class=SpellE>LifestyleDAO</span>,
LLC, an active Delaware limited liability company (“Company”).<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'><span
style='mso-tab-count:1'>            </span><b>WHEREAS</b>, Company operates a lifestyle
club utilizing cryptographically secure blockchain organization management
tools to automate and improve transparency around certain aspects of
organizational governance of the club’s business, commonly known as a
“decentralized autonomous organization” or a “DAO;”<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'><span
style='mso-tab-count:1'>            </span><b>WHEREAS</b>, Company on or about
January 15, 2023, is offering for sale a digital collectible known as a Lifestyle
Membership Token (“the Membership Token”).<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-indent:.5in;line-height:normal'><b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>WHEREAS</span></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>, this
Agreement shall govern Company and Purchaser and Purchaser’s rights with its Lifestyle
Membership Token.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-indent:.5in;line-height:normal'><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpFirst style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>No Promise or Guarantee of DAO</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>.<span
style='mso-tab-count:1'> </span>The purchase of the Membership Token is a
transaction in goods and not a promise to provide, or a guarantee of receipt
of, future services from Company, although Company will make reasonable efforts
to ensure that a DAO comes into existence which allows Purchaser to unlock
expanded functionality associated with the Membership Token. Right to
membership in the lifestyle club is subject to club rules, the terms of which are
determined if and when the club comes into existence in coordination with the
DAO.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;line-height:normal'><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>No Future Rights; No Guarantee of Future
Performance</span></u></b><span lang=EN style='font-size:12.0pt;font-family:
"Times New Roman",serif'>. Purchaser understands and agrees that purchase of
the Membership Token does not grant he/she/it any rights and carries with it no
guarantee of future performance of any kind by Company. Purchaser is not entitled,
as a holder of the Membership Token, to vote or receive dividends or profits or
be deemed the holder of shares or a membership interest in Company by virtue of
ownership of the Membership Token, nor will anything contained herein be
construed as granting any rights in the Company whatsoever. The Company may, in
the future, offer holders of the Membership Token the opportunity to
participate in massively open online governance via DAO. Purchaser agrees that
the functionality of any DAO established by the Company will be determined by the
Company in its sole and absolute discretion.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Assumption of Risk</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>.<span
style='mso-tab-count:1'> </span>Purchaser understands and agrees that the
development of the Company’s business is a significantly risky venture and Purchaser
acknowledges and assumes the risk that the Company may not be able to complete,
substantially or in part, the membership services, or any other aspect of its
operations and furthermore that a significant proportion of the funds generated
by the Membership Token drop are expected to be retained by the Company for any
lawful business purpose. <b>YOUR PURCHASE OF THE MEMBERSHIP TOKEN COULD TO THE
COMPLETE LOSS OF YOUR MONEY AND NO PAYMENTS WILL BE RETURNED. THE MEMBERSHIP
TOKEN IS CREATED AND DELIVERED TO PURCHASEER AT THE SOLE RISK TO PURCHASER AND
ON AN “AS-IS” BASIS. PURCHASER HAS NOT RELIED ON ANY REPRESENTATIONS OR
WARRANTIES MADE BY COMPANY OR ANY OTHER PERSON OUTSIDE OF THE CONTEXT OF THIS
AGREEMENT, INCLUDING BUT NOT LIMITED TO CONVERSATIONS OF ANY KIND, INCLUDING
BUT NOT LIMITED TO WHETEHR SUCH STATEMENTS ARE MADE THROUGH ORAL OR EELCTRONIC
COMMUNICATION, OR ANY WHITE PAPER, SOCIAL MEDIA POST OR WEBSITE. PURCHASER
FURTHER UNDERSTANDS AND AGREES THAT THE MEMBERSHIP TOKEN IS NOT A SECURITY, AND
THAT PURCHASER IS SPECIFICALLY ADVISED THAT THE MEMBERSHIP TOKEN IS FOR
ENTERTAINMENT AND INDIVIDUAL USE ONLY. THERE IS NO GUARANTEEE THAT THE MEMBERSHIP
TOKEN WILL INCREASE IN VALUE. IN FACT, PURCHASER IS SPECIFICALLY INFORMED THAT
THE PURCHASE OF THE MEMBERSHIP TOKEN IS HIGHLY RISKY, AND THAT THE BUSINEESS OF
THE COMPANY IS VOLATILE WITH HIGH RISK ASSOCIATED THEREWITH. THE MEMBERSHIP
TOKEN IS FOR INDIVIDUAL AND ENTERTAINMENT PURPOSES ONLY, IT SHOULD NOT BE
PURCHASED AS AN INVESTMENT OR WITH THE INTENT TO SELL FOR A PROFIT.</b><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Choice of Law; Arbitration Provision</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>.<span
style='mso-tab-count:1'>       </span>IN CONSIDERATION OF THE PURCHASE OF THE MEMBERSHIP
TOKEN, PURCHASER AND COMPANY AGREE TO ARBITRATE ALL DISPUTES BETWEEEN THE
PARTIES, INCLUDING BUT NOT LIMITED TO, ALL DISPUTES RELATED TO THIS AGREEMENT,
ALL DISPUTES RELATED TO THE PURCHASE AND SALE OF THE MEMBERSHIP TOKEN, ANY AND
ALL CLASS ACTION CLAIMS. THE PARTIES AGREE THAT ANY AND ALL CONTROVERSIES,
INDIVIDUAL CLAIMS, CLASS CLAIMS OR DISPUTES WITH ANYONE (INCLUDING THE COMPANY
AND ANY EMPLOYEE, MEMBER, OFFICER, DIRECTOR, OR SHAREHOLDER OF THE COMPANY, IN
THEIR CAPACITY AS SUCH OR OTHERWISE), ARISING OUT OF, RELATING TO, OR RESULTING
FROM THE PURCHASE OF THE MEMBERSHIP TOKEN, INCLUDING ANY BREACH OF THIS
AGREEMENT, SHALL BE SUBJECT TO BINDING ARBITRATION UNDER THE ARBITRATION
PROVISIONS SET FORTH IN THE FEDERAL ARBITRATION ACT AND PURSUANT TO NEW YORK
LAW<a name="OLE_LINK10"></a><a name="OLE_LINK9"><span style='mso-bookmark:OLE_LINK10'>.
</span></a><b style='mso-bidi-font-weight:normal'>THE PARTIES UNDERSTAND THAT
THIS AGREEMENT TO ARBITRATE APPLIES TO ANY AND ALL DISPTES THAT THE PARTIES
HAVE OR MAY HAVE IN THE FUTURE FOR ANY AND ALL MATTERS, INCLUSONG ALL TORT
CLAIMS, STATUTORY CLAIMS OR ANY OTHER CLAIMS</b>.<a name="_Ref298752563"><o:p></o:p></a></span></p>

<p class=MsoListParagraphCxSpLast><span style='mso-bookmark:_Ref298752563'><i
style='mso-bidi-font-style:normal'><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></i></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span
style='mso-bookmark:_Ref298752563'><i style='mso-bidi-font-style:normal'><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>Procedure</span></i></span><span
style='mso-bookmark:_Ref298752563'><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>. THE PARTIES AGREE THAT ANY ARBITRATION
WILL BE ADMINISTERED BY JUDICIAL ARBITRATION &amp; MEDIATION SERVICES, INC. (“<b
style='mso-bidi-font-weight:normal'>JAMS</b>”), PURSUANT TO ITS COMPREHENSIVE ARBITRATION
RULES &amp; PROCEDURES (THE “<b style='mso-bidi-font-weight:normal'>JAMS RULES</b>”),
WHICH ARE AVAILABLE AT </span></span><a href="http://www.jamsadr.com"><span
style='mso-bookmark:_Ref298752563'><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>http://www.jamsadr.com</span></span></a><span
style='mso-bookmark:_Ref298752563'><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>. THE ARBITRATOR SHALL HAVE THE POWER TO
DECIDE ANY MOTIONS BROUGHT BY ANY PARTY TO THE ARBITRATION, INCLUDING MOTIONS
FOR SUMMARY JUDGMENT AND/OR ADJUDICATION, AND MOTIONS TO DISMISS AND DEMURRERS,
APPLYING THE STANDARDS SET FORTH UNDER THE FEDERAL RULES OF CIVIL PROCEDURE. <span
style='text-transform:uppercase'>the arbitrator shall issue a written decision
on the merits. </span>THE ARBITRATOR SHALL HAVE THE POWER TO AWARD ANY REMEDIES
AVAILABLE UNDER APPLICABLE LAW, AND TTHE ARBITRATOR SHALL AWARD ATTORNEYS’ FEES
AND COSTS TO THE PREVAILING PARTY. <span style='text-transform:uppercase'>the
decree or award rendered by the arbitrator SHALL be entered as a final and
binding judgment in any court having jurisdiction thereof.</span> THE
ARBITRATOR SHALL ADMINISTER AND CONDUCT ANY ARBITRATION IN ACCORDANCE WITH SUBSTANTIVE
NEW YORK LAW, THE FEDERAL RULES OF CIVIL PROCEDURE AND THE FEDERAL RULES OF
EVIDENCE. <span style='text-transform:uppercase'>any arbitration under this
Agreement shall be conducted in NEW YORK, NEW YORK.</span></span></span><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;text-transform:
uppercase'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><i
style='mso-bidi-font-style:normal'><span lang=EN style='font-size:10.0pt;
mso-bidi-font-size:11.0pt'>Remedy</span></i><span lang=EN style='font-size:
10.0pt;mso-bidi-font-size:11.0pt'>. EXCEPT AS PROVIDED BY THIS AGREEMENT,
ARBITRATION SHALL BE THE SOLE, EXCLUSIVE, AND FINAL REMEDY FOR ANY DISPUTE
BETWEEN PURCHASER AND THE COMPANY. </span><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpFirst><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Waiver</span></u></b><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'>. Without
prejudice to any other provision of this Agreement, Purchaser acknowledges and
agrees that, except in the case of Company’s fraud or willful default, and
except as otherwise required by any non-waivable provision of applicable law, Company
shall not be liable in any manner whatsoever to Purchaser or any other person
for losses or damages of any kind, whether arising in contract, tort, or
otherwise, arising from the sale of the Membership Tokens to any person.
Additionally, Purchaser understands and agrees that Company is not responsible
for the actions of any third-parties utilized by Purchaser as part of
Purchaser’s use of the Membership Token. <o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;line-height:normal'><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpLast style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>All Sales Final/For Entertainment and
Individual Enjoyment Only, Not for Investment</span></u></b><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'>. <b>THIS IS NOT
AN INVESTMENT. THIS PRODUCT IS NOT DESIGNED TO BE FOR INVESTMENT PURPOSES. THE MEMBERSHIP
TOKEN IS NOT MEANT TO BE RE-SOLD AND IS NOT DESIGNED TO INCREASE IN VALUE. THE MEMBERSHIP
TOKEN IS A MEMBERSHIP ACCESS TOKEN ITEM FOR INDIVIDUAL ENJOYMENT ONLY.
PURCHASER UNDERSTANDS THAT THEY ARE BUYING A MEMBERSHIP AND THE MEMBERSHIP
TOKEN IS EVIDENCE OF THEIR MEMBERSHIP, NOTHING MORE. ALL PURCHASES ARE FINAL
AND PURCHASER UNDERSTANDS THAT SALE OF THE MEMBERSHIP TOKEN/MEMBERSHIP IS
STRICTLY PROHIBITED ABSENT APPROVAL FROM THE COMPANY, WHICH MAY BE WITHHELD BY
THE COMPANY FOR ANY REASON OR NO REASON. THE MEMBERSHIP IS FOR ENTERTAINMENT
AND ENJOYMENT PURPOSES.</b><b style='mso-bidi-font-weight:normal'><o:p></o:p></b></span></p>

<p class=MsoNormal><b style='mso-bidi-font-weight:normal'><span lang=EN
style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal align=center style='text-align:center;line-height:normal'><b
style='mso-bidi-font-weight:normal'><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>TERMS OF MEMBERSHIP<o:p></o:p></span></u></b></p>

<p class=MsoListParagraphCxSpFirst><b style='mso-bidi-font-weight:normal'><span
lang=EN style='font-size:12.0pt;line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b style='mso-bidi-font-weight:normal'><u><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>Authority
and Agreement to be Bound</span></u></b><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-bidi-font-weight:bold'>.<span
style='mso-tab-count:1'>     </span>By purchasing the </span><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Membership Token<span
style='mso-bidi-font-weight:bold'>, Purchaser is agreeing to Membership in
Company’s Lifestyle Club (“the Service”) wherein Purchaser may access certain
services provided to Purchaser by either Company or certain Third-Party Service
Providers selected by Company from time to time. </span>By accepting this
Agreement, or by accessing or using the Service, Purchaser represents that
he/she is at least 18 years old and has the legal capacity to be bound by this
Agreement; that all registration information he/she submits is truthful and
accurate; and if Purchaser is accepting this Agreement on behalf of a company
or another legal entity, he/she has the authority to bind such entity to this
Agreement, in which case the terms &quot;you&quot; or &quot;your&quot; shall
refer to the entity.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Privacy Policy</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>.<span
style='mso-spacerun:yes'>  </span>Access and use of the Service is subject to
the Company’s Privacy Policy available at </span><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:black;mso-ansi-language:EN-US'>Legal.lifestyledao.io/privacy</span><span
style='font-size:13.0pt;font-family:"Helvetica Neue";mso-bidi-font-family:"Helvetica Neue";
color:black;mso-ansi-language:EN-US'> </span><span style='font-size:12.0pt;
font-family:"Times New Roman",serif'><span style='mso-spacerun:yes'> </span><span
lang=EN><span style='mso-spacerun:yes'> </span>and such policy is incorporated fully
herein by reference. Company’s Privacy Policy is subject to change at any time
at the sole and complete discretion of Company.<o:p></o:p></span></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Right to Amend</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>.<span
style='mso-tab-count:1'>        </span>Company reserves the right to update and/or
amend this Agreement at any time, in its sole and absolute discretion, and will
notify Purchaser of such updates either via email or by posting such changes on
our Site, or such other reasonable means. </span><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:black;mso-ansi-language:EN-US'>Company
reserves the right to suspend and/expel Purchaser for violation of the terms of
this Agreement which determination is made in the sole and absolute discretion
of the Company.</span><span lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>The Services</span></u></b><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'>.<span
style='mso-spacerun:yes'>  </span>The Service shall consist of access to our Membership
Portal Site and the platform contained therein in which we make certain access,
perks, incentives, and discounts to venues, products, and services (the
&quot;Incentives&quot;) offered by a wide range of participating merchants and
vendors (&quot;Vendors&quot;) available to you (the &quot;Service&quot;).
Subject to and conditioned on your compliance with the terms of this Agreement,
you shall have a non-exclusive, non-transferable, non-sublicensable, limited
right, during the Term to access and use the Service.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>11.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Use of the Service/Sale and Trading
Prohibited</span></u></b><span lang=EN style='font-size:12.0pt;font-family:
"Times New Roman",serif'>.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpLast><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'><span
style='mso-tab-count:1'>            </span></span><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:black;mso-ansi-language:EN-US'>The
Buying/Selling/Trading of this Membership is strictly prohibited, including but
not limited to privately or on sites such as <span class=SpellE>OpenSea</span>,
<span class=SpellE>Rarible</span>, and the like. All valid memberships must be
purchased directly from Company, its minting services, and/or marketplace </span><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>Purchaser
agrees that he/she/it will not (and will not permit or encourage any Users or
other third party to) directly or indirectly:<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpFirst style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.0in;line-height:normal;mso-list:l2 level1 lfo5'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'><span style='mso-list:Ignore'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Use the Service in any manner or for any
purpose other than as permitted by this Agreement;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.0in;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.0in;line-height:normal;mso-list:l2 level1 lfo5'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'><span style='mso-list:Ignore'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Use the Service in violation of any
federal, state, local, or international laws or regulations;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-indent:1.0in'><span lang=EN style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.0in;line-height:normal;mso-list:l2 level1 lfo5'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'><span style='mso-list:Ignore'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Reproduce, duplicate, copy, sell, resell,
rent, lease, or exploit any portion of the Service, use of the Service, or
access to the Service without our prior written consent;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-indent:1.0in'><span lang=EN style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.0in;line-height:normal;mso-list:l2 level1 lfo5'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'><span style='mso-list:Ignore'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Use the Service to store or transmit
malicious code, viruses, Trojan horses, or worms of a destructive nature or
that may damage or harm the Site, Service, and/or our computer systems;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-indent:1.0in'><span lang=EN style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.0in;line-height:normal;mso-list:l2 level1 lfo5'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'><span style='mso-list:Ignore'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Attempt to gain unauthorized access to the
Service or to another User’s account or any related systems or networks;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-indent:1.0in'><span lang=EN style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.0in;line-height:normal;mso-list:l2 level1 lfo5'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'><span style='mso-list:Ignore'>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Take screenshots or videos of the Site
and/or Service in order to share the information with one of our competitors or
a company seeking to create and/or offer services similar to the Service; and <o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpLast style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.0in;line-height:normal;mso-list:l2 level1 lfo5'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'><span style='mso-list:Ignore'>(g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:black;mso-ansi-language:EN-US'>pay all membership fees and service fees
upon the time due, and Company reserves right to suspend or deactivate
membership for such violation.</span><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'><o:p></o:p></span></p>

<p class=MsoNormal><span lang=EN style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpFirst style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>12.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Notice of Unauthorized Use</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>. Purchaser
will immediately notify Company of any unauthorized use of his/her/its account
or the Service that comes to his/her/its attention. In the event of any such
unauthorized use, Purchaser will take all steps necessary to terminate such
unauthorized use.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;line-height:normal'><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>13.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Changes and Updates to the Service</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>.
Purchaser understands and agrees that Company may change the Service as it
refines and adds more features or content. Company reserves the right to
update, modify, or discontinue the features, functionality, content or other
aspects of the Service, including any and all Incentives, at any time, with or
without notice, in its sole and absolute discretion. Any new features that
augment or enhance the current Service, including the release of new tools and
resources, shall be subject to this Agreement. Company does not guarantee the
availability of the Service and/or any of the Incentives or Discounts contained
therein, and they are all subject to change at any time without notice.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>14.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>User Account(s)</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>. To
obtain access to the Service as a User, Purchaser is required to obtain a login
to the Site, by completing a KYC application form providing his/her/its name,
email address and other pertinent information. When registering as a User Purchaser
must meet the conditions set forth in Section 7 above, including: (a) providing
true, accurate, current and complete information as requested by the
registration form for the Service, and (b) maintaining and promptly updating
throughout the Term such information to keep it true, accurate, current and
complete. Your User account login is personal to you and may not be shared or
used by anyone else. However, subject to the terms of this Agreement, you may
add or create separate logins for as many Users as you would like.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>15.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Payment Terms</span></u></b><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'>. </span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:black;
mso-ansi-language:EN-US'>Any Request made to Company and/or any of its
partners, may incur a service fee. Purchaser agrees to apay any service fees
charged by Company. Purchaser understands and agrees that<span
style='mso-spacerun:yes'>  </span>the service fee amounts are unknown at the time
of purchase. Additionally, Purchaser agrees to pay a yearly membership fee in
the amount of $5,555. In the event Purchaser cancels a reservation booked
through either Company or one of Company’s third-party providers, Purchaser
agrees to pay a cancellation fee the amount of which is determined solely and
exclusively by Company. Purchaser understands that Company may increase yearly
membership dues at their sole discretion. </span><b><u><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p></o:p></span></u></b></p>

<p class=MsoListParagraphCxSpMiddle><b><u><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p><span
 style='text-decoration:none'>&nbsp;</span></o:p></span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>16.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Cancellation and Termination</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>.
Purchaser can cancel their user account and use of the Services at any time. As
per this Agreement, all sales of the Membership Token are final and there are
no refunds. Upon termination of this Agreement or cancellation of account, all
licenses and other rights granted to Purchaser hereunder will immediately
terminate and Purchaser will lose access to and will cease all use of the
Service (including all perks, incentives, discounts and other benefits offered
through the Service). For avoidance of doubt, Purchaser understands and agrees
that any Incentives that he/she/it obtained through the Service during the Term
may not be used beyond the termination of this Agreement or cancellation of
account. Additionally, Company, in its sole and absolute discretion, may
terminate this Agreement if Purchaser violates any provision of this Agreement.
Any cancellation and/or termination by either party also includes the
cancellation and/or termination of the ability to sell the membership on/in the
Company’s marketplace.<b><u><o:p></o:p></u></b></span></p>

<p class=MsoListParagraphCxSpMiddle><b><u><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p><span
 style='text-decoration:none'>&nbsp;</span></o:p></span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>17.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Third Party Linked Service and Content; No
Liability for Transactions With Third-Party Service Providers</span></u></b><b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>. The Site,
APIs, and Service may contain features and functionalities linking Purchaser or
providing Purchaser with certain functionality and access to third party
content, including but not limited to websites, directories, servers, networks,
systems, information and databases, software, applications, programs, products
and/or services, and the Internet as a whole. When Purchaser engages a third
party’s website or service which is linked to the Site and/or Service, Purchaser
is interacting with the third party and not with Company. Such linked websites
are not under Company’s control and Company is not responsible for the contents
of any linked website, or any link contained within a linked website, or any
changes or updates to such websites maintained by third parties. All Incentives
that Purchaser pursues, or other transactions Purchaser engages in using the
Service are between Purchaser and the transacting party. Company is not an
agent of any transacting party or third-party service provider, nor is Company
a direct party in any such transaction. Any such activities, and any terms
associated with such activities, are solely between Purchaser and the
applicable third-party. Company shall have no liability, obligation or
responsibility for any such correspondence, purchase or promotion between Purchaser
and any such third-party. Purchaser should make whatever investigation he/she/it
feels necessary or appropriate before proceeding with any online or offline
transaction with any of these third parties. Purchaser is solely responsible
for he/she/its dealings with any third party related to the Service, including
the delivery of and payment for goods and services.</span></b><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;line-height:normal'><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>18.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Proprietary Rights</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>. Company
owns and reserves all right, title and interest in and to the Service and our
Site, including all intellectual property rights therein. Purchaser grants to Company
and its contractors the right to use, modify, adapt, reproduce, distribute,
display and disclose any information or data Purchaser transmits to the Service
solely to the extent necessary to provide Purchaser with the Service or as
otherwise permitted by this Agreement and Company’s Privacy Policy.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>19.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Trademarks</span></u></b><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'>.<span
style='mso-tab-count:1'>  </span>The Site and/or Service contain valuable
trademarks owned and used by Company to distinguish the Site and Service from
those of others. The Site and/or Service may also contain references to other
entities’ trademarks and service marks, but such references are for
identification purposes only and are used with the permission of their
respective owners. Company does not claim ownership in, or any affiliation
with, any third-party trademarks or service marks appearing on the Site and/or
Service. Purchaser agrees not to use or display any trademarks he/she/it does not
own without Company’s prior written consent or the consent of the owner of such
mark.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>20.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Confidentiality</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>. In
connection with this Agreement, Purchaser may receive or have access to
Confidential Information of Company. For purposes of this Agreement,
&quot;Confidential Information&quot; means the terms of this Agreement, and all
technical and non-technical information concerning or related to Company’s or
its affiliates’ respective products, services, and general business operations,
information of or concerning Company’s or its affiliates’ users or employees,
and any and all data, information and materials related to any of the
foregoing. Purchaser agrees that he/she/it shall not disclose Confidential
Information to any third party, except to he/she/its employees who have a need
to know and are bound by written confidentiality obligations no less
restrictive than these. Confidential Information remains the sole and exclusive
property of Company.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>21.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Warranties</span></u></b><b><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'>.</span></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'> <b>COMPANY
PROVIDES ITS SERVICE TO PURCHASER &quot;AS IS&quot;, &quot;WITH ALL
FAULTS&quot; AND &quot;AS AVAILABLE&quot;. PURCHASER AGREES THAT ANY USE OF AND
RELIANCE UPON THE SERVICE (INCLUDING ANY AND ALL OF THE INFORMATION, CONTENT,
AND/OR MATERIALS CONTAINED THEREIN, OR RESULTS OBTAINED THEREFROM) BY PURCHASER
IS AT PURCHASER’S SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
COMPANY MAKES NO (AND SPECIFICALLY DISCLAIM ALL) REPRESENTATIONS OR WARRANTIES
OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING,
WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, SATISFACTORY
QUALITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. IN ADDITION,
COMPANY DOES NOT WARRANT THAT ACCESS TO THE SERVICE OR SITE WILL BE
UNINTERRUPTED, ERROR-FREE OR FREE OF HARMFUL COMPONENTS, OR THAT INFORMATION
OBTAINED THROUGH THE SERVICE WILL BE ACCURATE OR RELIABLE. COMPANY MAKES NO
CLAIMS OR PROMISES WITH RESPECT TO ANY THIRD PARTY, SUCH AS THE VENDORS OR
ADVERTISERS LISTED ON THE SITE. ACCORDINGLY, COMPANY IS NOT LIABLE TO PURCHASER
FOR ANY LOSS OR DAMAGE THAT MIGHT ARISE FROM THEIR ACTIONS OR OMISSIONS.
PURCHASER’S PURCHASE AND USE OF THE PERKS, DISCOUNTS, OR PRODUCTS AND SERVICES
OFFERED BY THIRD PARTIES THROUGH THE SITE IS AT PURCHASER’S OWN DISCRETION AND
RISK.</b><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;line-height:normal'><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>22.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Indemnification</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>.
Purchaser agrees to indemnify, defend, and hold Company (including its
subsidiaries, Members, employees, agents, licensors, suppliers, officers,
directors and third-party providers) harmless against all claims (including all
associated costs, expenses,<span style='mso-spacerun:yes'>  </span>losses,
liabilities, damages, fines, penalties and attorneys’ fees) from third parties,
arising out of or related to: (a) Purchaser’s breach of these Terms, (b) Purchaser’s
violation of any applicable laws, rules, or regulations in connection with the
Service, or (c) Purchaser’s conduct.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>23.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Limitation of Liability</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>. TO THE
MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL COMPANY, OR ITS
MEMBERS, OFFICERS, OR EMPLOYEES, BE LIABLE TO PURCHASER (OR ANY PARTY CLAIMING
THROUGH YOU) FOR ANY LOST PROFITS, LOSS OF BUSINESS OPPORTUNITY, LOSS OF DATA,
OR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE
DAMAGES, REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING
NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE. FURTHER, TO THE MAXIMUM EXTENT
PERMITTED UNDER APPLICABLE LAW, COMPANNY, AND ITS MEMBERS, OFFICERS, EMPLOYEES,
AND VENDORS MAXIMUM AGGREGATE LIABILITY TO PURCHASER FOR LOSSES OR DAMAGES THAT
PURCHASER SUFFERS IN CONNECTION WITH THE SERVICE, SITE OR THIS AGREEMENT SHALL
NOT EXCEED THE TOTAL AMOUNTS PAID BY PURCHASER TO COMPANY IN THE TWO MONTHS
IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH LIABILITY.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:.5in;line-height:normal;mso-list:l4 level1 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>24.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Miscellaneous Provisions</span></u></b><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle><span lang=EN style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.5in;line-height:normal;mso-list:l4 level2 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'><span style='mso-list:Ignore'>24.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Entire Agreement</span></u><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'>. This Agreement
constitutes the entire agreement of the Parties regarding the Service and the
purchase of the Membership Token. It supersedes any and all proposals, oral or
written, negotiations, conversations, discussions, or agreements between the
Parties relating to your use of the Service (including, without limitation, any
prior versions of this Agreement).<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.5in;line-height:normal'><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.5in;line-height:normal;mso-list:l4 level2 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'><span style='mso-list:Ignore'>24.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>No Waiver</span></u><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'>. Ay failure to
enforce any of the provisions of this Agreement or to exercise any rights or
remedies under this Agreement will not be construed as a waiver or
relinquishment to any extent of our right to assert or rely upon any such
provision, right or remedy in that or any other instance.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-indent:1.5in'><span lang=EN style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.5in;line-height:normal;mso-list:l4 level2 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'><span style='mso-list:Ignore'>24.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Severability</span></u><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'>. If any provision
of this Agreement is held to be invalid, illegal or unenforceable, the Parties
agree that such provision shall be construed in accordance with applicable law
as nearly as possible to reflect the original intentions of the Parties, and
that the remaining provisions shall remain in full force and effect.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-indent:1.5in'><span lang=EN style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.5in;line-height:normal;mso-list:l4 level2 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'><span style='mso-list:Ignore'>24.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Agreement Binding on Successors</span></u><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif'>. The
provisions of this Agreement shall be binding upon and shall inure to the
benefit of the Parties hereto, their heirs, administrators, successors and
assigns.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:0in;mso-add-space:auto;
text-indent:1.5in'><span lang=EN style='font-size:12.0pt;line-height:115%;
font-family:"Times New Roman",serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpLast style='margin-left:0in;mso-add-space:auto;
text-align:justify;text-indent:1.5in;line-height:normal;mso-list:l4 level2 lfo4'><![if !supportLists]><span
lang=EN style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman"'><span style='mso-list:Ignore'>24.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><u><span lang=EN style='font-size:12.0pt;
font-family:"Times New Roman",serif'>Survival of Terms</span></u><span lang=EN
style='font-size:12.0pt;font-family:"Times New Roman",serif'>. After your
subscription to the Service any and all terms of this Agreement that by their
nature may survive termination of this Agreement shall be deemed to survive
such termination.<o:p></o:p></span></p>




`

export default ReactHtmlParser(htmlString)
