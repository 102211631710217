import { addDoc, collection } from "firebase/firestore"
import Persona from "persona"
import { useState } from "react"

import { useAccount } from "wagmi"
import "../App.css"
import db from "../lib/firebase"
import TOSModal from "./TOSModal"

const KYC = ({ status, setStatus }) => {
  const [saving, setSaving] = useState(false)
  const [signedTOS, setSignedTOS] = useState(false)
  const [user, setUser] = useState({
    email: "",
    phone: "",
  })
  const { address } = useAccount()

  const createUserInDB = async (fields, status) => {
    setSaving(true)

    const nftObject = {
      firstName: fields["name-first"].value,
      lastName: fields["name-last"].value,
      email: user.email,
      kycStatus: status === "completed" ? "passed" : "failed",
      address: fields["crypto-wallet-address"].value,
      phoneNumber: user.phone,
      signedTOS,
      nftId: null,
    }

    try {
      await addDoc(collection(db, "users"), nftObject).then((userObject) => {
        setStatus(status)
      })
    } catch (e) {
      setStatus(status)
    }

    setSaving(false)
  }

  const client = new Persona.Client({
    templateId: process.env.REACT_APP_TEMPLATE_ID,
    environmentId: process.env.REACT_APP_ENVIORMENT,
    fields: {
      "crypto-wallet-address": address,
    },
    onComplete: ({ status, fields }) => {
      createUserInDB(fields, status)
    },
    onCancel: ({ inquiryId, sessionToken }) => console.log("onCancel"),
    onError: (error) => console.log(error),
  })

  const startClient = () => {
    client.open()
  }

  if (saving) return <div className="initial-message">Loading...</div>

  return (
    <>
      {status === null ? (
        signedTOS ? (
          <button onClick={startClient} className="button">
            Begin KYC Process
          </button>
        ) : (
          <TOSModal
            signedTOS={signedTOS}
            setSignedTOS={setSignedTOS}
            setUserInfo={(userEmail, userPhone) =>
              setUser({
                email: userEmail,
                phone: userPhone,
              })
            }
          />
        )
      ) : status === "completed" ? (
        <p className="message">Thank you for completing the KYC!</p>
      ) : (
        <p className="message">Sorry you failed the KYC</p>
      )}
    </>
  )
}

export default KYC
