import { useState } from "react"

import CoinbaseCommerceButton from "react-coinbase-commerce"
import "react-coinbase-commerce/dist/coinbase-commerce-button.css"

const Payment = () => {
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [paymentError, setPaymentError] = useState(false)

  return (
    <div className="payment-wrapper">
      <p>Please proceed to the payment of choice below</p>

      {paymentSuccess ? (
        <p>Thank you for your purchase!</p>
      ) : paymentError ? (
        <p>Sorry, there was an error with your payment. Please try again.</p>
      ) : (
        <div className="payment-button-wrapper">
          <CoinbaseCommerceButton
            className="button"
            checkoutId="6df4a1ff-d517-4336-ae60-6aec996adebc"
            onChargeSuccess={() => setPaymentSuccess(true)}
            onChargeFailure={() => setPaymentError(true)}
          />
          <a
            className="button"
            href="https://checkout.stripe.com/c/pay/cs_live_a1LESUMMV3MufvKv7G33a9eJbyMh6JZJHg74M1LR1U1ltkPFWG1HAzkNPj#fidkdWxOYHwnPyd1blppbHNgWjA0SEJda0ZHNHJuNFJ0cTRtX3wzcEBzNGd8dUh2Uz0xYzBBXW48QEwwM2p1dkdTRERcUmx3QnVmXG9QN2ljVXJpTDFkbVxwZ0RiX0FMa1BUSDdUSmhcS2huNTUyN21jVmBKUCcpJ3VpbGtuQH11anZgYUxhJz8nM2pAMnNQNGtHNm5XPFV8YVRXJ3gl"
            target="_blank"
            rel="noreferrer"
          >
            Pay With Credit Card
          </a>
        </div>
      )}

      <div>
        <p>Once payment is received our team member will be in contact.</p>
        <p>Please note your confirmation number in case of a payment issue.</p>
      </div>
    </div>
  )
}

export default Payment
