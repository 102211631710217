import "../App.css"

const Status = ({ status }) => {
  console.log("status", status)

  return (
    <div className="status">
      <div className={`status-icon ${status}`} />
      <span>KYC Status: {status}</span>
    </div>
  )
}

export default Status
