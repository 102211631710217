import "@rainbow-me/rainbowkit/styles.css"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { collection, getDocs, query, where } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useAccount } from "wagmi"
import "./App.css"
import ConnectProvider from "./components/ConnectProvider"
import ConnectWallet from "./components/ConnectWallet"
import Home from "./components/Home"
import KYC from "./components/KYC"
import Payment from "./components/Payment"
import Status from "./components/Status"

import logo from "./images/lifestyle-dao.png"
import db from "./lib/firebase"

const App = () => {
  const { address, isConnected } = useAccount()
  const [status, setStatus] = useState(null)

  useEffect(() => {
    if (isConnected && address) {
      const fetchUser = async () => {
        setStatus(null)
        const usersRef = collection(db, "users")

        const usersAddress = query(usersRef, where("address", "==", address))

        const users = await getDocs(usersAddress)

        if (users.docs.length > 0) {
          setStatus(users.docs[0].data().kycStatus)
        }
      }

      fetchUser()
    }
  }, [isConnected, address])

  return (
    <ConnectProvider>
      <div id="root " className="App">
        <ConnectWallet />
        {isConnected ? (
          status && (status === "completed" || status === "passed") ? (
            <div>
              <Status status={status} />
              <Payment />
            </div>
          ) : (
            <KYC status={status} setStatus={setStatus} />
          )
        ) : (
          <Home />
        )}

        <footer>
          <div className="side-bar" />
          <img src={logo} alt="logo" />
          <div className="side-bar" />
        </footer>
      </div>
    </ConnectProvider>
  )
}

export default App
