import { ConnectButton } from "@rainbow-me/rainbowkit"

const ConnectWallet = () => {
  return (
    <div className="connect-wallet">
      <ConnectButton />
    </div>
  )
}

export default ConnectWallet
