import card from "../../images/card.png"
import logo from "../../images/lifestyle-dao.png"
import "./home.css"

import {
  faCar,
  faMessage,
  faPlane,
  faSailboat,
  faShield,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ConnectButton } from "@rainbow-me/rainbowkit"
import ConnectWallet from "../ConnectWallet"

const accessNetworkItems = [
  {
    title: "Top Level Dining and Entertainment Reservations",
    desc: "Our network of professionals have robust relationships with the top dining establishments worldwide.",
    icon: faUtensils,
  },
  {
    title: "Global Executive Protection & Security",
    desc: "Whether for you individually, your family, or your business. LifestyleProtect provides top level protection solutions Globally.",
    icon: faShield,
  },
  {
    title: "Access to over 7k private aircraft",
    desc: "LifestyleAir’s worldwide network of aircraft is awaiting your next desired flight!",
    icon: faPlane,
  },
  {
    title: "180 exotic cars nationwide!",
    desc: "LifestyleDrive does not stop at exotic cars, it includes SUV and other private driving services.",
    icon: faCar,
  },
  {
    title: "Over 1400 Yachts worldwide!",
    desc: "Whether a day trip or a month charter, our partners are ready for you and your guests to come aboard!",
    icon: faSailboat,
  },
]

const Home = () => (
  <div className="container text-white p-4">
    <div className="hero grid md:grid-cols-2 gap-10 place-items-end md:place-items-center md:p-10 md:h-screen py-24">
      <div className=" grid gap-10 text-left order-2 md:order-1">
        <div className="grid gap-10">
          <span className="border-b-2 text-xl border-teal md:text-xl pb-4 md:w-max">
            Thank you for your interest in joining
            <span className="font-bold text-teal"> LifestyleDAO</span> !
          </span>
          <p className="text-md leading-8">
            LifestyleDAO is a membership access program providing top-level
            glove service and preferred access at restaurants & night-life
            destinations, hotels & resorts, sports & entertainment venues,
            yachting & air travel, and much more on the way!
          </p>
        </div>
        <div className="grid gap-4">
          <span>Please connect your wallet to complete:</span>
          <ol className="list-decimal pl-10 grid leading-7 gap-4">
            <li className="text-teal">Sign Terms & Conditions</li>
            <li className="text-teal">KYC verification</li>
            <li className="text-teal">Payment for membership</li>
          </ol>
        </div>
      </div>
      <a
        href="https://lifestyledao.io/"
        target="_blank"
        rel="noreferrer"
        className="order-1 md:order-2"
      >
        <img alt="logo" src={logo} className="w-full max-w-[500px] " />
      </a>
    </div>
    <section className="grid md:grid-cols-2 gap-10 place-items-center py-24 md:px-10 text-left">
      <div className="order-2 md:order-1">
        <p className="pb-4 text-xl">
          By holding a{" "}
          <span className="font-bold text-teal text-xl">LifestyleDAO</span>{" "}
          Membership Token
        </p>
        <ul className="list-disc pl-10 grid leading-7 gap-4">
          <li>
            24/7 Lifestyle Concierge provided by Quintessentially (~8k per year
            value)
          </li>
          <li>
            1.6 Million Perk and Discount driven Experiences provided by The
            Select Card (~$450 per year value)
          </li>
          <li>
            Member Only Programming allowing for you to connect with: athletes,
            entrepreneurs, entertainers, and other top level professionals.
          </li>
        </ul>
      </div>
      <img
        alt="card"
        src={card}
        className="w-full max-w-[400px] order-1 md:order-2"
      />
    </section>
    <section className="py-24 md:px-">
      <p className="text-xl pb-24">
        <span className="text-bold text-teal pb-10">Our</span> Access Network
      </p>
      <div className="flex  flex-wrap items-start gap-10 justify-evenly">
        {accessNetworkItems.map((item) => (
          <div className="grid place-items-center gap-4 md:w-[300px]">
            {" "}
            <FontAwesomeIcon
              icon={item.icon}
              size="3x"
              className="text-teal"
            />{" "}
            <span>{item.title}</span>
            <p className="text-gray-500">{item.desc}</p>
          </div>
        ))}
      </div>
    </section>
    <section className="grid md:grid-cols-2 gap-10 place-items-center py-24 md:px-10 text-left">
      <img alt="card" src={card} className="w-full max-w-[400px] " />
      <div className="grid gap-4">
        <p>
          <span className="font-bold text-teal">Phase 1</span> - Diamond Tier
          Membership
        </p>
        <p>
          Current Price — <span className="font-bold text-teal">$5,555</span>
        </p>
        <p className="leading-8">
          It is one thing to gain utility through a token, but to be able to
          connect and network with top people all around the world is priceless.
          Additionally, your membership token will also serve as your voting
          token to participate in community wide polling of activity and
          decisions.
        </p>
        <p className="text-gray-500">
          <span className="font-bold text-teal">LifestyleDAO</span> is the
          future; a top-level lifestyle conglomerate bringing together the
          worlds most prestigious brands.
        </p>
      </div>
    </section>
    <div className="pt-24 grid place-items-center gap-10">
      <p className="font-bold text-xl">
        <span className="text-teal">Level Up Your Lifestyle</span> Today!
      </p>
      <ConnectButton label="Connect Wallet to Join" />
    </div>
  </div>
)

export default Home
