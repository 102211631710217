import * as Dialog from "@radix-ui/react-dialog"
import React, { useState } from "react"
import Terms from "../utils/Terms"

const TOSModal = ({ signedTOS, setSignedTOS, setUserInfo }) => {
  const [checkedAgree, setCheckedAgree] = useState(false)
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [error, setError] = useState("")

  const validPhone = phone.match(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  )
  const validEmail = email.includes("@")

  const checkValidation = () => {
    if (!validPhone) {
      setError("Please enter a valid phone number")
      return false
    }
    if (!validEmail) {
      setError("Please enter a valid email")
      return false
    }
    return true
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button className="button">
          Agree to Lifestyle DAO Terms of Service
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">
            LifestyleDAO Terms of Service
          </Dialog.Title>
          {Terms}
          {error && <p className="error">{error}</p>}
          <fieldset className="Fieldset border-top">
            <label className="Label" htmlFor="email">
              Enter Email
            </label>
            <input
              className="Input"
              type="email"
              placeholder="satoshi@btc.com"
              value={email}
              onChange={(e) => {
                setError("")
                setEmail(e.target.value)
              }}
            />
          </fieldset>
          <fieldset className="Fieldset">
            <label className="Label" htmlFor="phone">
              Enter Phone #
            </label>
            <input
              className="Input"
              type="tel"
              placeholder="### - ### - ####"
              value={phone}
              onChange={(e) => {
                setError("")
                setPhone(e.target.value)
              }}
            />
          </fieldset>

          <div className="input-container">
            <input
              type="checkbox"
              value={checkedAgree}
              onChange={() => setCheckedAgree((checked) => !checked)}
              className="agree-input"
            />
            <span>I agree to the Terms and Service</span>
          </div>
          <button
            className={`submit ${
              checkedAgree && !!phone && !!email && !!!error
                ? null
                : "not-signed"
            }`}
            onClick={
              checkedAgree && !!phone && !!email && !!!error
                ? () => {
                    const isValid = checkValidation()

                    if (isValid) {
                      setSignedTOS(true)
                      setUserInfo(email, phone)
                    }
                  }
                : null
            }
          >
            Submit
          </button>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              x
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default TOSModal
